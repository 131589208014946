import axios from "axios";
import config from "../config";

const default_timeout = 12000;

export const getFastwayConsignmentsQuote = (data) => {
    return axios.post(config.backendURLPrefix + '/get-fastway-consignments-quote', {
        data
    }, {
        timeout: default_timeout,
        params: {}
    });
}

