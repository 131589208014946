import {useLocation, withRouter} from 'react-router-dom';
import { Steps, Typography, message } from "antd";
import {useState, useEffect} from 'react';
import './main.css';
import {EditOrder} from "./editOrders";
import {getURLParam} from "./components/utils";
import ImportEBay from "./importEBay";
import EBayConfirmationTable from "./eBayConfirmation";
import {
    getActiveEBayTokenFromLocalStorage,
    modifyActiveEBayTokenLocalStorage,
    refreshEBayAccessToken
} from "./components/auth";

const Main = () => {
    const location = useLocation();

    const [step, setStep] = useState(getURLParam(location.search)['step'] ? parseInt(getURLParam(location.search)['step'], 10) : 0);
    const [nextData, setNextData] = useState(null);
    const [options, setOptions] = useState(null);

    const accessToken = getActiveEBayTokenFromLocalStorage();
    const refreshToken = getActiveEBayTokenFromLocalStorage(true);

    const next = (data, options) => {
        console.log(">>>>> next data step="+step);
        console.log(data);
        console.log("+++++ next options");
        console.log(options);   
        setOptions(options);     
        setNextData(data);
        setStep(step + 1);
    }

    useEffect(() => {
        // console.log(">>> Main useEffect accessToken");
        // console.log(accessToken);
        // console.log(">>>=== Main useEffect refreshToken");
        // console.log(refreshToken);        
        if (!accessToken && refreshToken) {
            // console.log(">>> Main useEffect refreshEBayAccessToken 111<<<<");
            refreshEBayAccessToken(refreshToken).then(
                res => {
                    // console.log(">>> Main useEffect refreshEBayAccessToken res <<<<");
                    // console.log(res);
                    if(res&&res.data&&res.data.access_token){
                        modifyActiveEBayTokenLocalStorage(res.data);
                        window.location.reload();
                    }else{
                        message.error("Failed to get valid access token!").then();
                    }
                }
            ).catch((error) => {
                console.log(">>> Main useEffect refreshEBayAccessToken error <<<<");
                console.log(error);
            });
        }
    }, [accessToken, refreshToken]);

    const steps = [
        {
            title: 'Import Orders',
            component: <ImportEBay onNext={next} />
        },
        {
            title: 'Edit Orders',
            component: <EditOrder dataSource={nextData} options={options} onNext={next} />
        },
        {
            title: 'Update eBay Order Status',
            component: <EBayConfirmationTable dataSource={nextData} />
        }
    ];

    return (
        <>
            <Steps current={step} className='stepArea'>
                {
                    steps.map(
                        (item, index) => (
                            <Steps.Step key={index} title={item.title} />
                        )
                    )
                }
            </Steps>
            <Typography.Title className="contentTitle" level={4}>Step {step + 1}: {steps[step].title}</Typography.Title>
            <div className='contentArea'>
                {
                    steps[step].component
                }
            </div>
        </>
    );
}

export default withRouter(Main);
