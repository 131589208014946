import React, { useState, useRef, useEffect } from 'react';
import { InputNumber, Col, Row, notification, Select, Input } from 'antd';
import ProForm, { ProFormGroup } from '@ant-design/pro-form';
import { debounce } from "lodash";
import { getFastwayConsignmentsQuote } from "./fastway_services";
import './parcelItem.css';
import LoadingSpin from "./LoadingSpin/index";

export const ParcelItem = ({record, index, options, shipmentsIndex, shipmentsActionRef, quoteParamProps, onPriceUpdated, onShowServiceAndTotal}) => {
    // console.log(">>>>>>>====== ParcelItem row: ", shipmentsActionRef?.current?.get(Number(shipmentsIndex)));
    // console.log(">>>>>>>====== ParcelItem index: ", index);
    // console.log(">>>>>>>====== ParcelItem record: ", record);

    const packageTypeParcel = "P";
    const packageTypeSatchel = "S";
    const packageTypeMyItems = "M";
    const defaultItemQuantity = 1;
    const defaultFuelSurcharge = "Fuel Surcharge";

    //for Parcel
    const parcelItemParam = quoteParamProps.Items[0]; 

    //for Satchel
    const satchelItemParam = {      
        Quantity: defaultItemQuantity,
        PackageType: packageTypeSatchel,        
    }; 

    //for My Items
    const myItemsParam = {      
        Quantity: defaultItemQuantity,
        PackageType: packageTypeMyItems,        
    };   
    
    const lastItemParam = useRef({
        "P" : parcelItemParam,
        "S": satchelItemParam,
        "M": myItemsParam,
    });

    const [priceLoading, setPriceLoading] = useState(false);
    const [currentPackageType, setCurrentPackageType] = useState(packageTypeParcel);

    const handlePackageTypeChange = (value) => {
        console.log("======= handlePackageTypeChange value="+value); 
        if (typeof onShowServiceAndTotal === 'function') {
            onShowServiceAndTotal(false); 
        }     
        setCurrentPackageType(value); 
        let parentListRow = shipmentsActionRef?.current?.get(Number(shipmentsIndex));
        if(parentListRow&&parentListRow.Items){
            let row = parentListRow.Items[index];
            if(row){
                // 目前只有Reference是共有的
                //对于parcel，因为已经有dimension和weight，所以可以直接查询
                //对于Satchel和My Items的话，需要等选择size/item之后再查询
                if(value===packageTypeParcel){
                    let itemParam = lastItemParam.current[packageTypeParcel];
                    parentListRow.Items[index] = {
                        ...itemParam
                    };
                    console.log("======= handlePackageTypeChange Parcel :"); 
                    console.log(parentListRow.Items[index]);
                    // setPriceLoading(true);
                    debouncedRequest();
                }else if(value===packageTypeSatchel){
                    let itemParam = lastItemParam.current[packageTypeSatchel];
                    parentListRow.Items[index] = {
                        ...itemParam
                    };
                    console.log("======= handlePackageTypeChange Satchel :");  
                    console.log(parentListRow.Items[index]);
                    if(parentListRow.Items[index]['SatchelSize']){
                        console.log("======= handlePackageTypeChange Satchel has SatchelSize"); 
                        // setPriceLoading(true);
                        debouncedRequest();
                    }
                }else if(value===packageTypeMyItems){
                    let itemParam = lastItemParam.current[packageTypeMyItems];
                    parentListRow.Items[index] = {
                        ...itemParam
                    };
                    console.log("======= handlePackageTypeChange MyItems :");  
                    console.log(parentListRow.Items[index]);
                    if(parentListRow.Items[index]['myItemId']){
                        console.log("======= handlePackageTypeChange MyItems has myItemId"); 
                        // setPriceLoading(true);
                        debouncedRequest();
                    }
                }   
                console.log(">>>>>>>====== handlePackageTypeChange row: ", shipmentsActionRef?.current?.get(Number(shipmentsIndex)));
            }             
        }
            
    } 

    const handleSatchelSizeChange = (value, option) => {
        console.log("======= handleSatchelSizeChange value="+value);   
        console.log(option); 
        let parentListRow = shipmentsActionRef?.current?.get(Number(shipmentsIndex));
        if(parentListRow&&parentListRow.Items){
            let row = parentListRow.Items[index];
            if(row){
                parentListRow.Items[index]['SatchelSize']= value;
                let itemParam = lastItemParam.current[packageTypeSatchel];
                itemParam['SatchelSize']= value;
                lastItemParam.current[packageTypeSatchel] = itemParam;
                // setPriceLoading(true);
                debouncedRequest();
            }    
        }            
    } 
    
    const handleMyItemChange = (value, option) => {
        console.log("======= handleMyItemChange value="+value);   
        console.log(option); 
        let parentListRow = shipmentsActionRef?.current?.get(Number(shipmentsIndex));
        if(parentListRow&&parentListRow.Items){
            let row = parentListRow.Items[index];
            if(row){
                parentListRow.Items[index]['myItemId']= value;
                let itemParam = lastItemParam.current[packageTypeMyItems];
                itemParam['myItemId']= value;
                lastItemParam.current[packageTypeMyItems] = itemParam;
                // setPriceLoading(true);
                debouncedRequest();
            }    
        } 
    }   
    
    const handleDimensionWeightQuantityChange = (source, value) => {
        console.log(">>>>>>> handleDimensionWeightQuantityChange source="+source);
        console.log("======= handleDimensionWeightQuantityChange value="+value);
        let parentListRow = shipmentsActionRef?.current?.get(Number(shipmentsIndex));
        if(parentListRow&&parentListRow.Items){
            let row = parentListRow.Items[index];
            if(row){
                parentListRow.Items[index][source]= value;
                let itemParam = lastItemParam.current[packageTypeParcel];
                itemParam[source]= value;
                lastItemParam.current[packageTypeParcel] = itemParam;
                console.log(">>>>>>>===== handleDimensionWeightQuantityChange lastItemParam=");
                console.log(lastItemParam.current);
                // setPriceLoading(true);
                debouncedRequest();
            }    
        }         
    } 
      
    const getItemPrice = async () => {
        try {
            if (typeof onShowServiceAndTotal === 'function') {
                onShowServiceAndTotal(false); 
            } 
            let parentListRow = shipmentsActionRef?.current?.get(Number(shipmentsIndex));
            if(parentListRow&&parentListRow.Items){
                let items = parentListRow.Items;
                if(items){
                    console.log(">>>>>>> getItemPrice items="+JSON.stringify(items, null, 2));
                    //remove PackageType when MyItems
                    let itemsTmp = [];
                    for(var k = 0; k < items.length; k++ ){
                        itemsTmp[k] = {
                            ...items[k]
                        };

                        if(items[k]['PackageType']&&items[k]['PackageType']===packageTypeMyItems){
                            delete itemsTmp[k]['PackageType']; 
                        }
                    }
                    quoteParamProps.Items=itemsTmp;
                    console.log('>>>>>> getItemPrice quoteParamProps=');
                    console.log(quoteParamProps);
                    for(var i = 0; i < items.length; i++ ){
                        if(items[i].PackageType===packageTypeParcel){
                            if(items[i].WeightDead===undefined||items[i].WeightDead===0||items[i].WeightDead===null
                                ||items[i].Length===undefined||items[i].Length===0||items[i].Length===null
                                ||items[i].Width===undefined||items[i].Width===0||items[i].Width===null
                                ||items[i].Height===undefined||items[i].Height===0||items[i].Height===null)
                            {
                                console.log('***** Parcel inputs are not completed! *****');
                                return;
                            }                            
                        }
                        /*
                        else if(items[i].PackageType===packageTypeSatchel){
                            if(items[i].SatchelSize===undefined){
                                console.log('***** Satchel input is not completed! *****');
                                return;
                            }                           
                        }else if(items[i].PackageType===packageTypeMyItems){
                            if(items[i].myItemId===undefined){
                                console.log('***** MyItems input is not completed! *****');
                                return;
                            }                           
                        }   */     
                    }    

                    setPriceLoading(true);  
                    getFastwayConsignmentsQuote(quoteParamProps).then(
                        (res) => {
                            // console.log('getFastwayConsignmentsQuote response='+JSON.stringify(res));
                            if (res.data['errors']) {
                                // console.log('getFastwayConsignmentsQuote got error');
                                notification.error({
                                    message: "Error",
                                    description: res.data['errors'],
                                    placement: "topLeft",
                                    duration: 20,  //默认 4.5 秒后自动关闭，配置为 null 则不自动关闭
                                });                        
                            } else {
                                // console.log('getFastwayConsignmentsQuote no error');
                                let pricesTmp = res.data['data'];
                                let fuelSurchargeTmp = 0;
                                for (let i = 0; i < pricesTmp.items.length; i++) {
                                    let item = pricesTmp.items[i];
                                    if(item['description']===defaultFuelSurcharge){
                                        fuelSurchargeTmp = item['total'];
                                        break;
                                    }
                                }  
        
                                let itemsPriceTmp = 0;
                                for (let i = 0; i < pricesTmp.items.length; i++) {
                                    let item = pricesTmp.items[i];
                                    if(item['productType']==='Labels'){
                                        itemsPriceTmp = itemsPriceTmp+item['total']; 
                                    }
                                }   

                                if (typeof onPriceUpdated === 'function') {
                                    const result = {
                                        "itemsPrice" : Number(itemsPriceTmp),
                                        "fuelSurchargePrice": Number(fuelSurchargeTmp),
                                        "prices": pricesTmp,
                                    };
                                    onPriceUpdated(result);
                                }    
                            }                    
                        }
                    ).catch(
                        (error) => {
                            console.log('getFastwayConsignmentsQuote error='+JSON.stringify(error));
                            notification.error({
                                message: error.name,
                                description: error.message,
                                placement: "topLeft",
                                duration: 20,  //默认 4.5 秒后自动关闭，配置为 null 则不自动关闭
                            });
                        }
                    ).finally(
                        () => {
                            setPriceLoading(false);  
                            // console.log('getFastwayConsignmentsQuote finally');
                        }
                    );
                }    
            }    
            // console.log('++++ getItemPrice done!');            
        }catch (err) {
            console.log("getItemPrice err="+err);
            notification.error({
                message: "Unknown Error",
                description: "Failed to get items price! error="+err,
                placement: "topLeft",
                duration: 20,  //默认 4.5 秒后自动关闭，配置为 null 则不自动关闭
            });            
        } finally {
            // setPriceLoading(false);   
        }              
    } 
    
    const debouncedRequest = useRef(
        debounce(async () => {
            await getItemPrice();
        }, 1000)
    ).current;

    useEffect(() => {
        // console.log(">>>>>>>>>>>>> ParcelItem useEffect <<<<<<<<<<<<");   
        debouncedRequest();
        return () => {
            debouncedRequest.cancel();
        };
    }, [debouncedRequest]); 

    return (  
        <Row gutter={32}>  
            <Col span={24} className="flexToRow left itemGroupContainer">
                <ProFormGroup key="item_group_1">
                    <ProForm.Item
                        name="PackageType"
                        label="Package type"
                        className="packageTypeSelect"
                        rules={[{ required: true, message: 'Package type is required' }]}
                        initialValue={record.PackageType}
                    >
                        <Select
                            key={`package_type_select`}
                            options={options?.fastway_package_types?.map((item) => ({
                                label: item['label'],
                                value: item['value'],
                            }))}
                            placeholder="Select package type"
                            onSelect={(e) => {handlePackageTypeChange(e);}}
                        />
                    </ProForm.Item>	
                    <ProForm.Item
                        name="Quantity"
                        initialValue={record.Quantity}
                        rules={[{ required: true }]}
                    >
                        <InputNumber addonBefore={<><span className="spanRequired">*</span><span className="spanAddon">Quantity</span></>}  min={1} onChange={(e)=>{handleDimensionWeightQuantityChange("Quantity",e)}}/>
                    </ProForm.Item>                     
                    <ProForm.Item
                        name="Reference"
                    >
                        <Input addonBefore='Reference' />
                    </ProForm.Item>    
                    {
                        currentPackageType  === packageTypeSatchel ?
                            <>
                                <ProForm.Item
                                    name="SatchelSize"
                                    label="Size"
                                    className="sizeSelect"
                                    rules={[{ required: true, message: 'Satchel size is required' }]}
                                    initialValue={record.SatchelSize}
                                >
                                    <Select
                                        key={`size_select`}
                                        options={options?.fastway_satchel_sizes?.map((item) => ({
                                            label: item['label'],
                                            value: item['value'],
                                        }))}
                                        placeholder="Select satchel size"
                                        onSelect={(value, option)=>{handleSatchelSizeChange(value, option)}}
                                    />
                                </ProForm.Item>	
                            </>
                        : null
                    }  
                    {
                        currentPackageType  === packageTypeMyItems ?
                            <>
                                <ProForm.Item
                                    name="myItemId"
                                    label="Item"
                                    className="itemSelect"
                                    rules={[
                                        { required: true, message: "My item is required" },
                                    ]}
                                    initialValue={record.Item}
                                >
                                    <Select
                                        key={`item_select`}
                                        options={options?.fastway_my_items?.map((item) => ({
                                            label: item['description'],
                                            value: item['myItemId'],
                                        }))}
                                        placeholder="Select my item"
                                        onSelect={(value, option)=>{handleMyItemChange(value, option)}}
                                    />
                                </ProForm.Item>	
                            </>
                        : null
                    }                      

                </ProFormGroup>   
                {
                    currentPackageType  === packageTypeParcel ?
                        <>
                            <ProFormGroup key="item_group_2">
                                <ProForm.Item
                                    name="Length"
                                    initialValue={record.Length}
                                    rules={[{ required: true, message: 'Length is required' }]}
                                >
                                    <InputNumber addonBefore={<><span className="spanRequired">*</span><span className="spanAddon">Length</span></>}   min={0.01}  onChange={(e)=>{handleDimensionWeightQuantityChange("Length",e)}} addonAfter="cm"/>
                                </ProForm.Item> 
                                <ProForm.Item
                                    name="Width"  
                                    initialValue={record.Width}
                                    rules={[{ required: true, message: 'Width is required' }]}                                     
                                >
                                    <InputNumber addonBefore={<><span className="spanRequired">*</span><span className="spanAddon">Width</span></>}  min={0.01} onChange={(e)=>{handleDimensionWeightQuantityChange("Width",e)}}  addonAfter="cm"/>
                                </ProForm.Item> 
                                <ProForm.Item
                                    name="Height"   
                                    initialValue={record.Height}
                                    rules={[{ required: true, message: 'Height/Depth is required' }]}                                    
                                >
                                    <InputNumber addonBefore={<><span className="spanRequired">*</span><span className="spanAddon">Height</span></>}  min={0.01} onChange={(e)=>{handleDimensionWeightQuantityChange("Height",e)}}  addonAfter="cm"/>
                                </ProForm.Item>  
                                <ProForm.Item
                                    name="WeightDead"  
                                    initialValue={record.WeightDead}
                                    rules={[{ required: true, message: 'Weight is required'}]}                             
                                >
                                    <InputNumber addonBefore={<><span className="spanRequired">*</span><span className="spanAddon">Weight</span></>}  min={0.001} onChange={(e)=>{handleDimensionWeightQuantityChange("WeightDead",e)}}  addonAfter="kg"/>
                                </ProForm.Item>                                                        
                            </ProFormGroup>                                 
                        </>
                    : null
                }                               

            </Col>
            <Col span={24}>
            {
                priceLoading ?
                    <LoadingSpin height={"40px"} margin={"20px 0 0 0"}/>
                : null 
            }                
            </Col>            
        </Row>        
    );  

}