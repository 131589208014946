import React from 'react';
import { Spin } from 'antd';
import './index.css';


const LoadingSpin = (props) => { 
    const {
        height,
        minHeight,
        width,
        minWidth,
        alignItems,
        justifyContent,
        size,
        margin,
        // ...restProps
    } = props; 

    return (
        <div className="spinContainer" style={{height: height,  minHeight: minHeight, width: width,  minWidth: minWidth, alignItems: alignItems, justifyContent: justifyContent, margin: margin }}>
            <Spin size={size===undefined ? 'default': size} spinning style={{
                margin: 24,
            }}/>
        </div>        
    );    

};

export default LoadingSpin; 