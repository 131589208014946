import './App.css';
import {Typography} from "antd";
import 'antd/dist/antd.css';
import { withRouter } from 'react-router-dom';
import Main from "./main";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <Typography.Title level={2}>BC Auto Sydney eBay Order Management 2023</Typography.Title>
            </header>
            <div className='App-content'>
                <Main />
            </div>
            <div className='App-footer'>
                <footer>&copy; {new Date().getFullYear()} <a href='https://aumagic.com.au' target='_blank' rel='noreferrer'>AuMagic Pty Ltd</a> All Rights Reserved</footer>
            </div>
        </div>
    )
}

export default withRouter(App);
