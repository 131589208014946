import md5 from 'md5';
import axios from "axios";
import {stringify} from "qs";
import config from "../config";

export const getEWEAuthFromLocalStorage = () => {
    const data = localStorage.getItem('eweUsrApi');
    if (data && data !== 'null' && data !== 'undefined') {
        return atob(data);
    }
    return null;
}

export const getFastwayAuthFromLocalStorage = () => {
    const data = localStorage.getItem('fastwayUsrApi');
    if (data && data !== 'null' && data !== 'undefined') {
        return atob(data);
    }
    return null;
}

export const getActiveEBayTokenFromLocalStorage = (refresh=false) => {
    const token = localStorage.getItem(`eBay${refresh ? 'Refresh' : 'Access'}Token`);
    const tokenExp = localStorage.getItem(`eBay${refresh ? 'Refresh' : 'Access'}TokenExp`);

    if (token && token !== 'null' && token !== 'undefined') {
        if (parseInt(tokenExp, 10) <= Date.now()) {
            if (refresh) {
                localStorage.removeItem(`eBayRefreshToken`);
                localStorage.removeItem(`eBayRefreshTokenExp`);
            } else {
                modifyActiveEBayTokenLocalStorage(null);
            }
            return null;
        }
        return token;
    }
    return null;
}

export const saveEWEAuthToLocalStorage = (username, apiKey) => {
    localStorage.setItem('eweUsrApi', btoa(`${username}:${apiKey}`));
}

export const saveFastwayAuthToLocalStorage = (apiKey) => {
    localStorage.setItem('fastwayUsrApi', btoa(`${apiKey}`));
}

export const modifyActiveEBayTokenLocalStorage = (access=null) => {
    if (access) {
        const time = Date.now() + access['expires_in'] * 1000;
        localStorage.setItem(`eBayAccessToken`, access['access_token']);
        localStorage.setItem(`eBayAccessTokenExp`, time.toString());
        if (access['refresh_token']) {
            const refreshTime = Date.now() + access['refresh_token_expires_in'] * 1000;
            localStorage.setItem(`eBayRefreshToken`, access['refresh_token']);
            localStorage.setItem(`eBayRefreshTokenExp`, refreshTime.toString());
        }
    } else if (!access) {
        localStorage.removeItem(`eBayAccessToken`);
        localStorage.removeItem(`eBayAccessTokenExp`);
    }
}

export const getDigest = (userName, orderSize, apiKey) => {
    function hexToBase64(str) {
        return btoa(String.fromCharCode.apply(null,
            str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
        );
    }
    return hexToBase64(md5(`${userName}${orderSize}pushUnCreatedShipments${apiKey}`));
}

export const getEBayAccessToken = (authCode) => {
    const data = stringify({
        'grant_type': 'authorization_code',
        'code': authCode,
        'redirect_uri': config.eBayAPIRedirect_uri
    });
    return axios.post('https://proxy.aumagic.com.au/?url=https://api.ebay.com/identity/v1/oauth2/token',
        data,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${btoa(`${config.eBayAPIClientID}:${config.eBayAPIClientSecret}`)}`
            }
        })
}

export const refreshEBayAccessToken = (refresh) => {
    const scopes = config.eBayAPIScopes;
    const scope = Array.isArray(scopes) ? scopes.join('%20') : scopes;
    const data = `refresh_token=${refresh}&grant_type=refresh_token&scope=${scope}`;
    // console.log(">>> refreshEBayAccessToken data <<<<");
    // console.log(data);    
    return axios.post('https://proxy.aumagic.com.au/?url=https://api.ebay.com/identity/v1/oauth2/token',
        data,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${btoa(`${config.eBayAPIClientID}:${config.eBayAPIClientSecret}`)}`
            }
        });
}

export const promptEBayLogin = (forceLogin=false) => {
    window.location.href =
        'https://auth.ebay.com/oauth2/authorize?' +
        `client_id=${config.eBayAPIClientID}` +
        `&redirect_uri=${config.eBayAPIRedirect_uri}` +
        `&response_type=code&scope=`+encodeURI(config.eBayAPIScopes.join(' '))+`${forceLogin ? '&prompt=login' : ''}`;
}
