import {Button, Space, Table, Typography} from "antd";
import {useState} from 'react';
import {getEBayOrders, updateEBayOrders} from "./components/services";
import {withRouter} from "react-router-dom";

const { Link } = Typography;

const EBayConfirmationTable = ({dataSource}) => {
    // const history = useHistory();
    const [data, setData] = useState(dataSource);
    const [orderLoading, setOrderLoading] = useState(false);

    const updateOrders = (orderArray) => {
        setOrderLoading(true);
        updateEBayOrders(orderArray).then(
            res => {
                getEBayOrders(orderArray, orderArray[0] === 'FASTWAYCOURIERS' ? 'fastway' : 'ewe').then(
                    res => {
                        setOrderLoading(false);
                        setData(res.data);
                    }
                ).catch((error) => {
                    setOrderLoading(false);
                    console.log(">>>>>>>>>>> getEBayOrders error:");
                    console.log(error);
                })
            }
        ).catch((error) => {
            setOrderLoading(false);
            console.log(">>>>>>>>>>> updateEBayOrders error:");
            console.log(error);
        }).finally(
            () => {
                setOrderLoading(false);
            }
        );
    }

    return (
        <Space size='large' direction='vertical'>
            <Table
                loading={orderLoading}
                dataSource={data}
                pagination={false}
                rowKey='orderId'
                columns={[
                    {
                        dataIndex: 'orderNumber',
                        title: 'Order Number'
                    },
                    {
                        dataIndex: 'lineItemId',
                        title: 'Line Item ID'
                    },
                    {
                        dataIndex: 'vendor',
                        title: 'Courier'
                    },
                    {
                        dataIndex: 'trackingNo',
                        title: 'Tracking Number'
                    },
                    {
                        dataIndex: 'orderFulfillmentStatus',
                        title: 'Status',
                        render: (text) => (
                            <Typography.Text
                                type={text === 'FULFILLED' ? 'success' : 'warning'}
                            >
                                {text}
                            </Typography.Text>
                        )
                    }
                ]}
            />
            <Space size='large' direction='vertical'>
                <Button type='primary' loading={orderLoading} onClick={() => updateOrders(data)}>Update Orders</Button>

                <Link href={`/?t=${Date.now()}`} style={{marginTop: '5vh', display: 'block'}} target="_self">
                    Send Other Packages
                </Link>               
            </Space>
        </Space>
    )
}

export default withRouter(EBayConfirmationTable);
