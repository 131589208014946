import React, { useState, useRef } from 'react';
import { Typography, Col, Row, Select, Input, message } from 'antd';
import ProForm, { ProFormList } from '@ant-design/pro-form';
import './fastwayShipment.css';
import { ParcelItem } from "./parcelItem";

const { Text } = Typography;
const { TextArea } = Input;

export const FastwayShipment = ({options, shippingPackageDetails, shipmentsIndex, shipmentsActionRef, qty, postcode, countryCode, instructionsPrivate, city}) => {
    // console.log(">>>>> FastwayShipment shippingPackageDetails");
    // console.log(shippingPackageDetails);
    const packageTypeParcel = "P";
    const defaultItemQuantity = 1;
    const defaultFuelSurcharge = "Fuel Surcharge";
    const defaultDeliveryOptionCode = "ATL";   

    //for Parcel
    const parcelItemParam = qty > 1 ?
    {
        WeightDead: shippingPackageDetails.weight*qty,
        Quantity: defaultItemQuantity,
        PackageType: packageTypeParcel,
    }
    :
    {
        Length: shippingPackageDetails.length,
        Width: shippingPackageDetails.width,
        Height: shippingPackageDetails.depth,
        WeightDead: shippingPackageDetails.weight,
        Quantity: defaultItemQuantity,
        PackageType: packageTypeParcel,
    }; 

    const quoteParamProps = {
        To : {
            Address: {
                PostalCode: postcode,
                Country: countryCode,
                Locality: city,
            }
        },
        Items: [parcelItemParam]
    };  
    // console.log(">>>>> FastwayShipment quoteParamProps");
    // console.log(quoteParamProps);    

    const [itemCount, setItemCount] = useState(1);
    const [deliveryOptionPrice, setDeliveryOptionPrice] = useState(0);  //with GST
    const [itemsPrice, setItemsPrice] = useState(0);
    const [showServiceAndTotal, setShowServiceAndTotal] = useState(false);
    const [fuelSurchargePrice, setFuelSurchargePrice] = useState(0); 
    const [prices, setPrices] = useState(); 

    const actionRef = useRef();

    const handleDeliveryOptionChange = (value, option) => {
        console.log("======= handleDeliveryOptionChange value="+value);   
        console.log(option);    
        setDeliveryOptionPrice(Number(option.price*1.1));  
    }   

    const handlePriceUpdatedCallBack = (result)  => {
        console.log("+++++++++ handlePriceUpdatedCallBack() result="); 
        console.log(result); 
        if(result){
            setItemsPrice(result.itemsPrice);         
            setFuelSurchargePrice(result.fuelSurchargePrice);  
            setPrices(result.prices); 
            setShowServiceAndTotal(true);  
        }
    }; 

    const handleShowServiceAndTotalCallBack = (value)  => {
        console.log("+++++++++ handleShowServiceAndTotalCallBack() value="+value);  
        setShowServiceAndTotal(value);        
    };

    return (
        <Row gutter={32}> 
            <Col span={12}>                                                                                                                                      
                <ProForm.Item
                    name="InstructionsPublic"
                    label={<Text strong>Instructions</Text>}
                >  
                    <TextArea
                        showCount
                        maxLength={100}
                        style={{ height: 60,}}
                        placeholder="Special instructions (will appear on label)"
                    />
                </ProForm.Item>                   
            </Col>
            <Col span={12}>
                <ProForm.Item
                    name="InstructionsPrivate"
                    label={" "}
                    initialValue={instructionsPrivate}
                >  
                    <TextArea
                        showCount
                        maxLength={100}
                        style={{ height: 60,}}
                        placeholder="Special instructions for your reference (will not appear on label)"
                    />
                </ProForm.Item>                   
            </Col>         
            <Col span={24}>
                <ProFormList 
                    name="Items"   
                    initialValue={[
                        parcelItemParam,          
                    ]} 
                    creatorRecord={{
                        PackageType: packageTypeParcel,
                        Quantity: defaultItemQuantity,
                    }}                
                    min={1}
                    max={1}
                    actionRef={actionRef}
                    actionGuard={{
                        beforeAddRow: async (defaultValue, insertIndex) => {
                            return new Promise((resolve) => {
                                setItemCount(itemCount+1);
                                setTimeout(() => resolve(true), 200);
                            });
                        },
                        beforeRemoveRow: async (index) => {
                            return new Promise((resolve) => {
                                if (index === 0) {
                                    message.error("This item can't not be deleted");
                                    resolve(false);
                                    return;
                                }
                                setItemCount(itemCount-1);
                                setTimeout(() => resolve(true), 200);
                            });
                        },
                    }}
                    label={<Text strong>Items</Text>}
                    itemRender={({}, { record, index }) => {
                        return (
                            <ParcelItem 
                                record={record} 
                                index={index}
                                options={options} 
                                shipmentsIndex={shipmentsIndex}
                                shipmentsActionRef={shipmentsActionRef}  
                                quoteParamProps={quoteParamProps}  
                                onPriceUpdated={handlePriceUpdatedCallBack}
                                onShowServiceAndTotal={handleShowServiceAndTotalCallBack}                                                                    
                            />
                        );
                    }}
                >  
                </ProFormList>  
            </Col> 
            {
                showServiceAndTotal ?
                <>
                    <Col span={24} className="servicePriceClasses">
                        <Typography.Title level={5} style={{ textAlign: "left",}}>
                            Services
                        </Typography.Title> 
                        <table className="priceTable">
                            <tbody>
                                <tr className="trBorderBottom">
                                    <td className="priceTitle"><Text strong>Fuel surcharge</Text></td>
                                    <td className="priceContent">{defaultFuelSurcharge}</td>
                                    <td className="price">${Math.round(fuelSurchargePrice * 100) / 100}</td>
                                </tr>  
                                <tr>
                                    <td className="priceTitle"><span className="spanRequired">*</span><Text strong>Delivery options</Text></td>
                                    <td className="priceContent">
                                        <ProForm.Item
                                            name="DELOPT"  
                                            initialValue={defaultDeliveryOptionCode} 
                                            rules={[{ required: true, message: "Delivery option is required" }]}                                    
                                        >
                                            <Select
                                                style={{ width: '50%', }}
                                                options={options?.fastway_delivery_options?.items?.map((item) => ({
                                                    label: item['name']+" ($"+item['price']+")",
                                                    value: item['code'],
                                                    price: item['price']
                                                }))}
                                                placeholder='Select delivery option'
                                                onChange = {(value, option)=>{handleDeliveryOptionChange(value, option)}}
                                            />                        
                                        </ProForm.Item> 
                                    </td>
                                    <td className="price">${Math.round(deliveryOptionPrice * 100) / 100}</td>
                                </tr>                                     
                            </tbody>                                 
                        </table>                
                    </Col>
                </>                        
                : null
            }                          
            {
                showServiceAndTotal ? 
                    <>
                        <Col span={24} className="totalPriceTableRow">
                            <table className="totalPriceTable">
                                <tbody>
                                    <tr>
                                        <td className="priceItem"><span className="priceTitle">{defaultItemQuantity} Item(s):</span>${Math.round(itemsPrice * 100) / 100}</td>
                                    </tr>  
                                    <tr>
                                        <td className="priceItem"><span className="priceTitle">Services:</span>${Math.round((deliveryOptionPrice+fuelSurchargePrice) * 100) / 100}</td>
                                    </tr>  
                                    <tr className="total">
                                        <td className="priceItem"><span className="priceTitle">Total (inc GST):</span>${Math.round((itemsPrice+deliveryOptionPrice+fuelSurchargePrice) * 100) / 100}</td>
                                    </tr> 
                                </tbody>                                   
                            </table>  
                        </Col>
                    </>
                : null

            }                              
        </Row>
    )
}
