import {
    getEWEAuthFromLocalStorage,
    getActiveEBayTokenFromLocalStorage
} from "./auth";
import axios from "axios";
import config from "../config";

const default_timeout = 12000;

export const getEbayItemsShippingPackageDetails = (itemIds) => {
    return axios.post(config.backendURLPrefix + '/get-ebay-items-shipping-package-details', {
        token: getActiveEBayTokenFromLocalStorage(),
        itemIds: itemIds
    }, {
        timeout: default_timeout,
        params: {}
    });
}

export const getMergedPDFs = (docList) => {
    return axios.post('https://v2.convertapi.com/convert/pdf/to/merge', {
        "Parameters": [
            {
                "Name": "Files",
                "FileValues": docList?.map(
                    item => ({
                        url: item
                    })
                )
            }
        ]
    }, {
        timeout: default_timeout,
        params: {
            'Secret': config.convertAPIKey
        }
    });
}

export const exportCSVFastwayOrders = (orderArray) => {
    if (orderArray?.length > 0) {
        const satchel = (input) => {
            if (input === '18') {
                return '18';
            }
            if (input === '6') {
                return '17';
            }
	        if (input === '1') {
                return '16';
            }            
            return '';
        }
        return orderArray.map(
            item => (
                {
                    'CARRIER': '',
                    'CONNOTE.': item['orderId'],
                    'WEIGHT': item['shippingClass'],
                    'ITEMS': 1,
                    'STATE GROUP': '',
                    'DELIVERY RUN': '',
                    'ACCOUNT NUMBER': '',
                    'COMPANY NAME': item['name'],
                    'CONTACT NAME': '',
                    'ADD1': item['address_1'],
                    'ADD2': item['address_2'],
                    'SUBURB': item['city'],
                    'STATE': item['state'],
                    'PCODE': item['postcode'],
                    'EMAIL': item['email'],
                    'PHONE': item['phone'],
                    'MOBILE': item['phone'],
                    'SPECIAL1': '',
                    'SPECIAL2': '',
                    'SPECIAL3': '',
                    'SATCHEL': satchel(item['shippingClass'])
                }
            )
        )
    }
    return null;
}

export const submitFastwayConsignments = (shipments) => {
    return axios.post(config.backendURLPrefix + '/create-consignments',
        {
            shipments: shipments
        }, {
            timeout: default_timeout,
            params: {
                type: 'fastway'
            }
        }
    );
}

export const submitEweConsignments = (shipments) => {
    return axios.post(config.backendURLPrefix + '/create-consignments',
        {
            username: getEWEAuthFromLocalStorage()?.split(':')[0],
            shipments: shipments
        }, {
            timeout: default_timeout,
            params: {
                type: 'ewe'
            }
        }
    );
}

export const initEBayOrders = () => {
    return axios.post(config.backendURLPrefix + '/init-ebay-orders', {
        token: getActiveEBayTokenFromLocalStorage(),
    }, {
        timeout: default_timeout,
    })
}

export const getEBayOrders = (orderArray, type) => {
    const params = {
        type: type
    }
    return axios.post(config.backendURLPrefix + '/get-ebay-orders', {
        token: getActiveEBayTokenFromLocalStorage(),
        orders: orderArray
    }, {
        timeout: default_timeout,
        params: params
    })
}

export const updateEBayOrders = (orderArray) => {
    return axios.post(config.backendURLPrefix + '/update-ebay-orders', {
        orders: orderArray,
        token: getActiveEBayTokenFromLocalStorage()
    }, {
        timeout: 120000,
    })
}
