import { Col, InputNumber, message, Radio, Row, Select, Space, Typography, Button, notification } from "antd";
import {
    parseDataEWE,
    parseDataFastway,
    EditableTypography,
    shippingClass
} from "./components/utils";
import {useState} from 'react';
import {
    getEBayOrders,
    submitFastwayConsignments,
    submitEweConsignments
} from "./components/services";
import {useForm} from "antd/es/form/Form";
import { useRef, useEffect} from 'react';
import { FastwayShipment } from "./components/fastwayShipment";
import { ProForm, ProFormList, ProFormText, ProCard } from '@ant-design/pro-components';
import './editOrder.css';

const { Text } = Typography;

export const EditOrder = ({dataSource, options, onNext}) => {
    const [data, setData] = useState([]);
    // const [vendor, setVendor] = useState('fastway');
    const [vendor, setVendor] = useState('ewe');
    const [submitting, setSubmitting] = useState(false);
    const shipmentsActionRef = useRef();

    const [form] = useForm();

    useEffect(() => {       
        const dataTmp = dataSource?.map(
            (item) => {
                const output = item;
                /*if (vendor === 'ewe') {
                    output.shippingClass = item.shippingClass === 'AU_ExpressDelivery' ? '3J35' : '3D35'
                } else {
                    output.shippingClass = ['18', '6', '25'].filter(option => option === item.shippingClass).length > 0 ? item.shippingClass : '25'
                }*/
                // 交换address 1和address 2的内容
                if(output){
                    const address_1 = output.address_1;
                    const address_2 = output.address_2;
                    output['address_1'] = address_2;
                    output['address_2'] = address_1;
                }                
                return output;
            }
        );
        // console.log(">>>>>EditOrder useEffect dataTmp");
        // console.log(dataTmp);
        // console.log("+++++EditOrder useEffect options");
        // console.log(options);  
        // form.setFieldsValue({data: dataTmp});

        setData(dataTmp);
    }, [dataSource, vendor, form])


    const switchVendor = (vendor) => {
        setVendor(vendor);
    }

    const removeOrder = (index) => {
        console.log(">>>>> removeOrder index="+index);
        /*let dataTmp = data;
        dataTmp.splice(index,1); 
        setData(dataTmp); */
        shipmentsActionRef?.current?.remove?.(index);       
    }

    const submitOrders = (values) => {
        const actions = (res) => {
            // console.log(">>>>> submitOrders res=");
            // console.log(res);
            if (res.data?.errors) {
                notification.error({
                    message: "Error",
                    description: res.data.errors,
                    placement: "topLeft",
                    duration: 10,  //默认 4.5 秒后自动关闭，配置为 null 则不自动关闭
                });                 
                setSubmitting(false);
            } else {
                getEBayOrders(res.data['consignment_list'], vendor).then(
                    response => {
                        if (response.data['errors']) {
                            // message.error(response.data['errors']).then();
                            notification.error({
                                message: "Error",
                                description: response.data.errors,
                                placement: "topLeft",
                                duration: 10,  //默认 4.5 秒后自动关闭，配置为 null 则不自动关闭
                            }); 
                        } else {
                            // console.log(">>>>> getEBayOrders response.data=");
                            // console.log(response.data);
                            onNext(response.data);
                        }
                    }
                ).finally(
                    () => {
                        setSubmitting(false);
                    }
                );
            }
        };

        setSubmitting(true);
        if (vendor === 'ewe') {
            submitEweConsignments(parseDataEWE(values['shipments'])).then(res => {
                actions(res);
            }).catch(
                (e) => {
                    message.error(JSON.stringify(e)).then();
                    setSubmitting(false);
                }
            );
        }

        if (vendor === 'fastway') {
            submitFastwayConsignments(parseDataFastway(values['shipments'])).then(res => {
                actions(res);
            }).catch(
                error => {
                    message.error(JSON.stringify(error)).then();
                    setSubmitting(false);
                    console.error(error);
                }
            );
        }
    }

    return (
        <>
            <ProForm onFinish={submitOrders}  submitter={{
                // 完全自定义整个区域
                render: (props, doms) => {
                    // console.log(props);
                    return [
                        <Button key="submit" type='primary' loading={submitting} onClick={() => props.form?.submit?.()}>
                            Create Consignments
                        </Button>,
                    ];
                },                            
            }}>
                <Space size='large' direction='vertical'>
                    <Radio.Group value={vendor} onChange={(e) => switchVendor(e.target.value)}>
                        <Radio value='ewe'>EWE</Radio>
                        <Radio value='fastway'>Fastway</Radio>
                    </Radio.Group>                
                </Space>
                {
                    data.length > 0 ?
                        <ProFormList
                            name="shipments" 
                            key="shipments"
                            className="shipmentsContainer"
                            actionRef={shipmentsActionRef}
                            min={1}
                            copyIconProps={false}
                            creatorButtonProps={false}
                            initialValue={data}
                            itemRender={({}, { record, index }) => {
                                // console.log("ProFormList shipments index="+index+": ", record);
                                let itemContent = [];

                                itemContent.push(
                                    <ProCard key={`orderDetails_${record.id}`} layout="default" colSpan={24} bordered>
                                        <Row gutter={32}>
                                            <Col span={12} className="flexToRow">
                                                <ProFormText readonly={true} name="orderId" label={<Text strong>Order Number:</Text>} /> 
                                            </Col>
                                            <Col span={12} className="flexToRow">
                                                <ProFormText  name="name" label={<Text strong>Name:</Text>} 
                                                    rules={[{ required: true, message: 'Name is required' }]} 
                                                > 
                                                    <EditableTypography el={Typography.Paragraph} />
                                                </ProFormText> 
                                            </Col>   
                                            <Col span={12}  className="flexToRow">
                                                <ProFormText  name="phone" label={<Text strong>Phone:</Text>}> 
                                                    <EditableTypography el={Typography.Paragraph} />
                                                </ProFormText> 
                                            </Col> 
                                            <Col span={12}  className="flexToRow">
                                                <ProFormText  name="email" label={<Text strong>Email:</Text>}> 
                                                    <EditableTypography el={Typography.Paragraph} />
                                                </ProFormText> 
                                            </Col> 
                                            <Col span={12}  className="flexToRow">
                                                <ProFormText  name="address_1" label={<Text strong>Address 1:</Text>} 
                                                    rules={[{ required: true, message: 'Address 1 is required' }]} 
                                                > 
                                                    <EditableTypography el={Typography.Paragraph} />
                                                </ProFormText> 
                                            </Col>  
                                            <Col span={12}  className="flexToRow">
                                                <ProFormText  name="address_2" label={<Text strong>Address 2:</Text>}> 
                                                    <EditableTypography el={Typography.Paragraph} />
                                                </ProFormText> 
                                            </Col> 
                                            <Col span={12}  className="flexToRow">
                                                <ProFormText  name="city" label={<Text strong>Suburb:</Text>} 
                                                    rules={[{ required: true, message: 'Suburb/City is required' }]} 
                                                > 
                                                    <EditableTypography el={Typography.Paragraph} />
                                                </ProFormText> 
                                            </Col> 
                                            <Col span={12}  className="flexToRow">
                                                <ProFormText  name="postcode" label={<Text strong>Postcode:</Text>} 
                                                    rules={[{ required: true, message: 'Postcode is required' }]} 
                                                > 
                                                    <EditableTypography el={Typography.Paragraph} />
                                                </ProFormText> 
                                            </Col>  
                                            <Col span={12}  className="flexToRow">
                                                <ProFormText  name="state" label={<Text strong>State:</Text>}> 
                                                    <EditableTypography el={Typography.Paragraph} />
                                                </ProFormText> 
                                            </Col>    
                                            <Col span={12}  className="flexToRow">
                                                <ProFormText  name="countryCode" label={<Text strong>Country:</Text>} 
                                                    rules={[{ required: true, message: 'Country is required' }]} 
                                                > 
                                                    <EditableTypography el={Typography.Paragraph} />
                                                </ProFormText> 
                                            </Col> 
                                            <Col span={12}  className="flexToRow">
                                                <ProFormText readonly={true} name="qty" label={<Text strong>Quantity:</Text>} /> 
                                            </Col> 
                                            <Col span={12}  className="flexToRow">
                                                <ProFormText readonly={true} name="price" label={<Text strong>Price:</Text>} /> 
                                            </Col>   
                                            <Col span={12}  className="flexToRow">
                                                <ProFormText readonly={true} name="ebayItemId" label={<Text strong>Ebay ItemId:</Text>} /> 
                                            </Col> 
                                            <Col span={12}  className="flexToRow">
                                                <ProFormText  name="ebayTransactionId" label={<Text strong>Ebay TransactionId:</Text>} 
                                                    rules={[{ required: true, message: 'Ebay TransactionId is required' }]} 
                                                > 
                                                    <EditableTypography el={Typography.Paragraph} />
                                                </ProFormText>                                              
                                            </Col> 
                                            <Col span={24}  className="flexToRow">
                                                <ProFormText readonly={true} name="item" label={<Text strong>Item Description:</Text>} /> 
                                            </Col>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
                                        </Row>
                                    </ProCard>                                     
                                );

                                itemContent.push(
                                    <ProCard key={`items_${record.id}`} layout="default" colSpan={24} bordered 
                                        title=                                        {
                                            vendor === 'fastway' ?
                                                <Text strong>Fastway</Text>
                                            :
                                                <Text strong>EWE</Text>
                                        }
                                    >
                                        {
                                            vendor === 'fastway' ?
                                                <FastwayShipment 
                                                    options={options} 
                                                    shippingPackageDetails={record.shippingPackageDetails} 
                                                    shipmentsIndex={index}
                                                    shipmentsActionRef={shipmentsActionRef}
                                                    qty={record.qty}
                                                    postcode={record.postcode}
                                                    countryCode={record.countryCode}
                                                    instructionsPrivate={record.address_1.startsWith("ebay:") ? record.address_1 : null}
                                                    city={record.city}
                                                />
                                            :
                                            <Row gutter={32}>
                                                <Col span={12} className="flexToRow">
                                                    <ProForm.Item name="weight" label={<Text strong>Weight:</Text>} className="numberItem"
                                                        rules={[{ required: true, message: 'Weight is required' }]}  
                                                    >
                                                        <InputNumber
                                                            style={{width: '100%'}}
                                                            min={0.1}
                                                            max={21.9}
                                                            addonAfter='KG'
                                                            placeholder={'Items\' total weight'}
                                                        />
                                                    </ProForm.Item>
                                                </Col>
                                                <Col span={12} className="flexToRow">
                                                    <ProForm.Item name="shippingClassNew" 
                                                        label={<Text strong>Shipping Class:</Text>}
                                                        initialValue={record.shippingClass === 'AU_ExpressDelivery' ? '3J35' : '3D35'}
                                                    >
                                                        <Select options={shippingClass} />
                                                    </ProForm.Item>      
                                                </Col>

                                            </Row>    
                                        }
                                    </ProCard>     
                                );

                                return (
                                    <ProCard
                                        key={`shipmentCard_${index}`}
                                        title={`Order ${record.orderId}`}
                                        style={{ marginBlockStart: 16 }}
                                        className="shipmentCard"
                                        direction="column"
                                        gutter={[0, 8]}
                                        bordered
                                        headerBordered
                                        collapsible
                                        extra={
                                            <Button type="primary" key={`delete_btn_${index}`} onClick={() => {removeOrder(index)}}>
                                                Delete
                                            </Button>
                                        }                                        
                                    >
                                        {itemContent}
                                    </ProCard>                                     
                                );
                            }}                                    
                        >
                        </ProFormList> 
                    : 
                    <p style={{textAlign: 'center', width: '100%'}}>No order selected</p>                            
                }                                
            </ProForm>
        </>
    )
}
