import {exportCSVFastwayOrders} from "./services";
import moment from "moment";
import React from "react";

export const EditableTypography = ({ value, onChange, el, props }) => {
    return React.createElement(
        el,
        {
        ...props,
        editable: {
            onChange(val) {
            return onChange(val);
            },
        },
        },
        value || 'N/A',
    );
};

export const parseCSVFromFile = (file, onFinish) => {
    const reader = new FileReader();

    reader.addEventListener('load', (e) => {
        const stringData = e.target.result;
        if (!stringData) {
            return false;
        }
        let outputArray = eBayCSVParser(stringData);
        outputArray = outputArray?.map(
            row => {
                const data = row;
                if (data['Shipping Service'] === 'Standard Parcel Delivery') {
                    data['Shipping Class'] = '3D35'
                } else {
                    data['Shipping Class'] = '3J35'
                }
                return data;
            }
        )
        onFinish(outputArray);
    });

    if (!file) {
        return false;
    }
    reader.readAsText(file);
}

export const unescapeQuoteMarks = (string) => string.replace(/['"](.+?)['"]/gm, `$1`)


export const eBayCSVParser = (stringContent) => {
    const csvOutputArray = [];
    let lines = stringContent?.split('\n').slice(0,-3).filter(item => item && item?.trim());
    const headerRow = lines[0].split(',').map(headerCol => unescapeQuoteMarks(headerCol))
    lines = lines.filter(
        (row) => {
            return row?.replaceAll('"', '').length > headerRow?.length
        }
    )
    const contentRows = lines.slice(1)

    contentRows.forEach(
        row => {
            const rowOutput = {}
            row?.split(',').forEach(
                (colValue, colIndex) => {
                    rowOutput[headerRow[colIndex]] = unescapeQuoteMarks(colValue)
                }
            )
            csvOutputArray.push(rowOutput)
        }
    )

    return csvOutputArray
}
export const parseDataFastway = (data) => {
    const shipments = [];
    // console.log(">>>>> parseDataFastway");
    // console.log(data);    
    if (data?.length > 0) {
        data.forEach(
            item => {
                let StreetAddress = '';
                if (item['address_1']&&item['address_1'].startsWith("ebay:")){
                    StreetAddress = item['address_2'];
                }else{
                    if (item['address_2']){
                        StreetAddress = item['address_1']+" "+item['address_2'];
                    }else{
                        StreetAddress = item['address_1'];
                    }
                }
                const obj = {
                    "orderId": item['orderId'],
                    "To": {
                        "ContactName": item['name'],
                        "BusinessName": "",
                        "PhoneNumber": item['phone'],
                        "Email": item['Buyer Email'],
                        "Address": {
                            "StreetAddress": StreetAddress,
                            "Locality": item['city'],
                            "StateOrProvince": item['state'],
                            "PostalCode": item['postcode'],
                            "Country": item['countryCode']
                        }                    
                    },
                    "Items": item['Items'],
                    "Services": [
                        {
                            "ServiceCode": "DELOPT",
                            "ServiceItemCode": item['DELOPT']
                        }                        
                    ],
                    "InstructionsPublic": item['InstructionsPublic'],
                    "InstructionsPrivate": item['InstructionsPrivate'],                        
                };
                shipments.push(obj);                
            }             
        )       
    }        
    return shipments;
}

export const parseDataEWE = (data) => {
    const shipments = [];
    if (data?.length > 0) {
        data.forEach(
            item => {
                const obj = {
                    sender_reference: item['orderId'],
                    salesPlatform: 'eBay',
                    ebayItemId: item['ebayItemId'],
                    ebayTransactionId: item['ebayTransactionId'],
                    to: {
                        name: item['name'],
                        phone: item['phone'],
                        email: item['Buyer Email'],
                        suburb: item['city'],
                        lines: [item['address_1'], item['address_2']],
                        state: item['state'],
                        postcode: item['postcode'],
                        country: item['countryCode']
                    },
                    items: [
                        {
                            product_id: item['shippingClassNew'],
                            weight: item['weight'],
                            merchandise: [
                                {
                                    goodsName: item['item'].substring(0, 45),
                                    goodsNameCn: item['item'].substring(0, 45),
                                    quantity: item['qty'],
                                    value: parseFloat(item['price'].replace(/.*\s\W/g, '')),
                                    weight: item['weight']
                                }
                            ]
                        }
                    ]
                }
                shipments.push(obj);
            }
        )
    }
    return shipments;
}

export const getURLParam = (search) => {
    const query = search.substring(1).split('&');
    const query_obj = {};
    query.forEach(
        item => {
            const keyValue = item.split('=')
            if (keyValue[0]) {
                query_obj[keyValue[0]] = keyValue[1]
            }
        }
    )
    return query_obj;
}

export const convertArrayCSV = (array) => {
    if (array?.length > 0) {
        let header = [];
        if (typeof array[0] === 'object') {
            header = Object.keys(array[0]).join(',');
        }
        const data = array.map(
            item => Object.values(item).map(value => typeof value === 'string' ? value.replaceAll(',', ' ') : value).join(',')
        ).join('\n');
        return header + '\n'+ data;
    }
}

export const generateCSVFileFromString = (string) => {
    if (string) {
        return `data:text/csv;charset=utf-8,${encodeURIComponent(string)}`;
    }
    return null;
}

export const shippingClass = [
    {
        label: 'PARCEL POST SIGNATURE',
        value: '3D35'
    },
    {
        label: 'EXPRESS POST + SIGNATURE',
        value: '3J35'
    }
]


export const download = (data) => {
    const pom = document.createElement('a');
    pom.setAttribute('href', generateCSVFileFromString(convertArrayCSV(exportCSVFastwayOrders(data))));
    pom.setAttribute('download', `ebay-export-${moment().format('YYYY_MM_DD_HH_mm_ss')}.csv`);

    if (document.createEvent) {
        const event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    }
    else {
        pom.click();
    }
}
