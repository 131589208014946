import './importEBay.css';
import {Button, message, Space, Spin, Table, Typography} from "antd";
import {getURLParam} from "./components/utils";
import { useState, useEffect } from 'react';
import {
    getActiveEBayTokenFromLocalStorage,
    getEBayAccessToken,
    modifyActiveEBayTokenLocalStorage,
    promptEBayLogin
} from "./components/auth";
import { useLocation, withRouter, useHistory } from 'react-router-dom';
import {initEBayOrders, getEbayItemsShippingPackageDetails} from "./components/services";

const ImportEBay = ({onNext}) => {
    const location = useLocation();
    const history = useHistory();

    const [eBayLogged, setEBayLogged] = useState(!!getActiveEBayTokenFromLocalStorage());
    // const [eBayLogged, setEBayLogged] = useState(true);
    const [options, setOptions] = useState(null);
    const [orders, setOrders] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [eBayLoading, setEBayLoading] = useState(false);
    const [goToLoading, setGoToLoading] = useState(false);

    useEffect(() => {
        const authCode = getURLParam(location.search)['code'] || null;
        if (authCode) {
            setEBayLoading(true);
            getEBayAccessToken(decodeURIComponent(authCode)).then((res) => {
                setEBayLoading(false);
                // console.log(">>>>>> getEBayAccessToken then");
                // console.log(res);
                if (!res.data.error && res.data['access_token']) {
                    modifyActiveEBayTokenLocalStorage(res.data);
                    setEBayLogged(true);
                } else {
                    message.error(res.data.error).then();
                    modifyActiveEBayTokenLocalStorage(null);
                }
                history.push('/');
            }).catch((error) => {
                setEBayLoading(false);
                modifyActiveEBayTokenLocalStorage(null);
                console.log(error);
                history.push('/');
            });
        }
        if (eBayLogged) {
            setEBayLoading(true);
            initEBayOrders().then(
                (res) => {
                    setEBayLoading(false);
                    //过滤掉Shipping Class是AU_Pickup的订单
                    let ordersTmp = [];
                    for(var i = 0; i < res.data?.orders?.length; i++){
                        if(res.data.orders[i].shippingClass==='AU_Pickup'){
                            ;
                        }else{
                            ordersTmp.push(res.data.orders[i]); 
                        }
                    }    
                    setOrders(ordersTmp);
                    setOptions(res.data.options);
                }
            )
        }
    }, [location.search, history, eBayLogged]);

    const goToEditOrders = () => {
        setGoToLoading(true);
        let itemsSelectedTmp = [];
        selectedOrders.forEach(function (order) {  
            itemsSelectedTmp.push(order["ebayItemId"]);  
        });  
        getEbayItemsShippingPackageDetails(itemsSelectedTmp).then(
            (res) => {
                setGoToLoading(false);
                if(res.data){
                    let data = res.data.data;
                    // console.log("1 goToEditOrders data=");
                    // console.log(data);
                    for(var j = 0; j < selectedOrders.length; j++){
                        var ebayItemId = selectedOrders[j]['ebayItemId'];
                        if (ebayItemId){
                            var itemShippingPackageDetails = data[ebayItemId];
                            // console.log("2 goToEditOrders itemShippingPackageDetails=");
                            // console.log(itemShippingPackageDetails);
                            if (itemShippingPackageDetails){
                                selectedOrders[j]['shippingPackageDetails'] = itemShippingPackageDetails;
                                selectedOrders[j]['weight'] = Number(itemShippingPackageDetails["weight"])*Number(selectedOrders[j]['qty']);
                            }
                        }
                    }  
                    // console.log("3.1 goToEditOrders selectedOrders=");
                    // console.log(selectedOrders);
                    setSelectedOrders(selectedOrders);

                }
                onNext(selectedOrders, options);
            }
        ).catch((error) => {
            setGoToLoading(false);
            console.log(">>> getEbayItemsShippingPackageDetails error <<<<");
            console.log(error);
        });

    }


    return (
        <div className={'selectMethodWrap'}>
            <Space size='large' direction='vertical'>
                {
                    eBayLoading ?
                        <Spin spinning />
                        :
                        <>
                            <div>
                                <p>
                                    <Typography.Text strong>eBay Login Status: <Typography.Text strong type={eBayLogged ? 'success' : 'warning'}>{eBayLogged ? 'Yes' : 'No'}</Typography.Text></Typography.Text>
                                </p>
                                {
                                    !eBayLogged && <p><Typography.Text strong type='warning'>You need to log in to eBay to continue</Typography.Text></p>
                                }
                                <Button onClick={() => promptEBayLogin(true)}>{ eBayLogged ? 'Switch eBay User' : 'Log In'}</Button>
                            </div>
                            <div className='importWrapper'>
                                {eBayLogged && <Table
                                    loading={eBayLoading}
                                    dataSource={orders}
                                    rowKey='orderId'
                                    rowSelection={
                                        {
                                            selectedRowKeys: selectedRows,
                                            onChange: (val, selected) => {
                                                console.log(">>>>>> onChange val");
                                                console.log(val);
                                                console.log(">>>>>> onChange selected");
                                                console.log(selected);                                                
                                                setSelectedRows(val);
                                                setSelectedOrders(selected);
                                            }
                                        }
                                    }
                                    columns={[
                                        {
                                            dataIndex: 'orderId',
                                            title: 'Order ID'
                                        },
                                        {
                                            dataIndex: 'name',
                                            title: 'Buyer Name'
                                        },
                                        {
                                            dataIndex: 'phone',
                                            title: 'Phone'
                                        },
                                        {
                                            dataIndex: 'email',
                                            title: 'Email'
                                        },
                                        {
                                            title: 'Address',
                                            render: (_, record) => (record['address_1'] +' '+ (record['address_2'] ? record['address_2'] : ''))
                                        },
                                        {
                                            title: 'City',
                                            dataIndex: 'city'
                                        },
                                        {
                                            title: 'State',
                                            dataIndex: 'state'
                                        },
                                        {
                                            title: 'Postcode',
                                            dataIndex: 'postcode'
                                        },
                                        {
                                            title: 'Country',
                                            dataIndex: 'countryCode'
                                        },
                                        {
                                            title: 'Shipping Class',
                                            dataIndex: 'shippingClass'
                                        },
                                        {
                                            title: 'Item',
                                            dataIndex: 'item'
                                        },
                                        {
                                            title: 'Qty',
                                            dataIndex: 'qty'
                                        },
                                        {
                                            title: 'Price',
                                            dataIndex: 'price'
                                        }
                                    ]}
                                />}
                                <Button disabled={!eBayLogged} type='primary' onClick={() => goToEditOrders()} loading={goToLoading}>Edit Orders</Button>
                            </div>
                        </>
                }
            </Space>
        </div>
    )
}

export default withRouter(ImportEBay)
