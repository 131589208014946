// eslint-disable-next-line import/no-anonymous-default-export
export default {
    backendURLPrefix: 'https://ebay-backend.bcautosydney.com.au',
    convertAPIKey: 'YLbhU2NYkQcSAoKi',
    eBayAPIClientID: 'DennyJia-bcauto-PRD-660a60563-5d2b0cf3',
    eBayAPIClientSecret: 'PRD-60a60563aa20-8fe7-43da-b078-b6ab',
    eBayAPIRedirect_uri: 'Denny_Jian-DennyJia-bcauto-ppxll',
    eBayAPIScopes: [
        'https://api.ebay.com/oauth/api_scope', 
        'https://api.ebay.com/oauth/api_scope/sell.inventory', 
        'https://api.ebay.com/oauth/api_scope/sell.marketing', 
        'https://api.ebay.com/oauth/api_scope/sell.account', 
        'https://api.ebay.com/oauth/api_scope/sell.fulfillment'
    ],  //更新scope需要清除浏览器中保存的cookie
    fastwayClientID : 'fw-fl2-SYD0230078-17ff3cb6d535',
    fastwaySecret : 'ec4911c9-6789-4549-96e1-477867486d89',
    fastwayScope : 'fw-fl2-api-au',
    fastwayTokenUrl : 'https://identity.fastway.org/connect/token',
    fastwayBaseUrl : 'https://api.myfastway.com.au',
    fastwayCustomerCountryCode : "AU",    
}
